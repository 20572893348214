/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.FiltersSummary {
  margin-bottom: 16px;
}

.FiltersSummaryCloseLink {
  display: inline-block;
  margin-right: 16px;

  &:not(:first-child) {
    display: none;
  }
}

.FiltersSummaryListItem {
  background: $purpleDarker;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 8px;
  margin-right: 16px;
  padding-left: 8px;
  padding-right: 40px;
  position: relative;

  &:after {
    color: $white;
    display: block;
    font-size: $fontSize-m;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s $defaultBezier;
  }

  &:hover {

    &:after {
      transform: translateY(-50%) scale(0.94);
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .FiltersSummary {
    margin-top: 10px;
  }

  .FiltersSummaryCloseLink {
    margin-right: 20px;
  }

  .FiltersSummaryListItem {
    margin-bottom: 12px;
    margin-right: 20px;
  }
}
