/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CategoryFilterShowMoreButton {
  clear: both;
  margin-top: 4px;
  text-align: center;

  :global {

    .Link {
      padding-left: 0;
      position: relative;

      &:after {
        bottom: -18px;
        font-size: $fontSize-Icon20;
        left: 50%;
        position: absolute;
        top: initial;
        transform: translateX(-50%);
      }
    }
  }

  &.Active {

    :global {

      .Link {

        &:after {
          bottom: initial;
          top: -15px;
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }
  }
}

.CategoryFilterButtons {
  display: none;
  overflow: hidden;

  :global {

    .react-slidedown {
      overflow: hidden;
      width: 100%;
    }
  }

  .CategoryFilterButton {
    max-height: 100%;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .CategoryFilterButtons {
    display: block;
  }

  .CategoryFilterCheckboxes {
    display: none;
  }
}

@media screen and ( $mobile-media ) {

  .CategoryFilterButtons {

    .CategoryFilterButton {
      max-height: 100%;
      padding-left: 15px;
      padding-right: 15px;

      &:after {
        right: 6px;
      }
    }
  }
}
