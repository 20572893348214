/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.RangeSliderFilterTitle {
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 43px;
}
