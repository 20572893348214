/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.FilterTile {

  &.Open {

    .FilterTileContent {
      display: block;
    }

    .FilterTileTitle {

      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.FilterTileTitle {
  cursor: pointer;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-left: 0;
  padding: 9px 0 9px 24px;
  position: relative;

  &:after {
    color: $purpleDarker;
    font-size: $fontSize-Icon20;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s $defaultBezier;
  }
}

.FilterTileContent {
  display: none;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .FilterTileTitle {
    padding-left: 0;

    &:after {
      right: 0;
    }
  }
}
