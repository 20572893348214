/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ShowMore {

  button {
    width: auto;

    a {
      padding-left: 24px;
      position: relative;
    }
  }
  margin-bottom: 50px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .ShowMore {

    button {
      width: 100%;
    }
  }
}
