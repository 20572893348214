/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Filters {
  margin-top: 10px;
}

.FiltersGroupTitle {
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 12px;
}

.FiltersGroupOptions {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  margin-bottom: 12px;
  margin-left: 8px;
  padding: 24px 0;

  &.Secondary {
    padding: 0;
  }

  &.RangeSlider {
    background: none;
    border: 0;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 32px;
    padding: 0;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .FiltersGroupOptions {
    background: none;
    border: 0;
    border-bottom: 1px solid $greyLightest;
    border-radius: 0;
    margin-bottom: 0;
    margin-left: 0;

    &.CategoryGroupOptions {
      padding-top: 5px;
    }

    &.RangeSlider {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;

      :global {

        .input-range {
          width: 92%;
        }
      }
    }
  }
}
