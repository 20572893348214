/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.MultipleFilter {
  margin-bottom: 24px;

  &.MultipleFilterNoMargin {
    margin-bottom: 0;
  }
}

.MultipleFilterShowMoreButton {
  border-top: 1px solid $greyLightest;
  cursor: pointer;
  margin-top: 4px;
  padding-bottom: 12px;
  padding-top: 10px;
  text-align: center;

  span {
    font-size: $fontSize-s;
    font-weight: $font-weight-l;
    letter-spacing: 0.5px;
    line-height: 20px;
    padding-left: 22px;
    position: relative;
    text-transform: uppercase;

    &:before {
      color: $purpleDarker;
      font-size: $fontSize-Icon18;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .MultipleFilterShowMoreButton {
    background: $greyLightestPlus;
    border: 1px solid $greyLightest;
    border-radius: 24px;
    box-sizing: border-box;
    margin: 18px auto;
    width: 176px;
  }
}
