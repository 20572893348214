/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SelectableButton {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  box-sizing: border-box;
  color: $greyDarkest;
  cursor: pointer;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-bottom: 8px;
  padding: 3px 27px;
  position: relative;
  text-align: center;
  width: 49%;

  &:nth-child(even) {
    clear: right;
    float: right;
  }

  &:nth-child(odd) {
    clear: both;
    float: left;
  }

  &.fullWidth {
    width: 100%;
  }

  &.noFloating {
    float: none;
  }

  &.Selected, &:hover {
    background: $purpleDarker;
    border-color: $purpleDarker;
    color: $white;
    transition: all .3s $defaultBezier;
  }

  &:after {
    color: $white;
    font-size: $fontSize-Icon14;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media ( hover: none ) {

  .SelectableButton {

    &:hover {
      background: $greyLightestPlus;
      border-color: $greyLightest;
      color: $greyDarkest;
    }

    &.Selected {
      background: $purpleDarker;
      border-color: $purpleDarker;
      color: $white;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $custom-460-media ) {

  .SelectableButton {

    &:nth-child(even), &:nth-child(odd) {
      hyphens: none;
      -webkit-line-clamp: 1;
      max-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
    }
  }
}
