/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.OptionsListItem {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 1px;

  &:hover {
    background: $purple;

    label {
      color: $white;
    }

    :global {

      .icon-check {

        &:before {
          opacity: .5;
        }
      }
    }
  }

  > * {
    display: block;
    padding: 0;
  }

  label {
    display: block;
    margin-left: 24px;
    padding: 12px 24px 12px 34px;
  }
}

.OptionsListSecondary {

  .OptionsListItem {
    border: 0;

    > * {
      display: block;
      padding: 0;
    }

    label {
      display: block;
      margin-left: 24px;
      padding: 4px 24px 4px 34px;
    }
  }
}

@media ( hover: none ) {

  .OptionsListItem {

    &:hover {
      background: $greyLightestPlus;

      label {
        color: $greyDarkest;
      }
    }

    > * {
      display: block;
      padding: 0;
    }

    label {
      display: block;
      margin-left: 24px;
      padding: 12px 24px 12px 34px;
    }
  }
}
