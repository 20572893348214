/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SortOrder {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}

.SortOrderTitle {
  color: $greyDarker;
  margin-right: 12px;
}

.SortOrderDropdown {
  width: 170px;
}
